@import "./src/js/front/theme/settings.scss";
.events-section {
    .uk-container {
        @media screen and (max-width: $breakpoint-medium) {
            padding: 0;
        }
    }
    .event {
        display: flex;
        margin-bottom: 50px;
        position: relative;
        @media screen and (max-width: $breakpoint-medium) {
            margin-bottom: 12px;
        }
        .details {
            width: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (max-width: $breakpoint-medium) {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 1;
                .uk-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
            .date {
                color: var(--colour-blue-light);
                @media screen and (max-width: $breakpoint-medium) {
                    color: var(--colour-white);
                }
            }
            .uk-card-title {
                margin-top: 0px;
                @media screen and (max-width: $breakpoint-medium) {
                    color: var(--colour-white);
                    font-size: 1.5rem;
                }
            }
        }
        .image {
            width: 54%;
            display: inline-block;
            position: relative;
            // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
            @media screen and (max-width: $breakpoint-medium) {
                width: 100%;
                background-color: rgba($primary-color, 0.5);
                img {
                    z-index: -1;
                    position: relative;
                }
            }
            .inner-border {
                width: 87%;
                height: 80%;
                margin: auto;
                border: 2px solid white;
                position: absolute;
                inset: 0;
                @media screen and (max-width: $breakpoint-medium) {
                    //  display: none;
                }
            }
        }
    }
}
