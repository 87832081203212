//Mixins & Constants Stylesheet

//Tools
// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
.image-replacement {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

//Use the best ampersand - http://simplebits.com/notebook/2008/08/14/ampersands-2/
.amp {
  font-family: Baskerville, "Goudy Old Style", Palatino, "Book Antiqua", serif;
  font-style: italic;
}

// @include css-gradient(#dfdfdf,#f8f8f8);
@mixin css-gradient($from: $primary-color, $to: $secondary-color) {
  background-color: $to;
  background-image: linear-gradient(to bottom, $from, $to);
}

// Vert gradient
@mixin vertical-gradient($bottom_colour, $top_colour) {
  background: $bottom_colour; //Fallback
  background: linear-gradient(to top, $bottom_colour 0%, $top_colour 100%);
}

// Diag Gradient
@mixin diagonal-gradient($bottom_colour, $top_colour) {
  background: $bottom_colour; //Fallback
  background: linear-gradient(45deg, $bottom_colour 0%, $top_colour 100%);
}

// Single side border-radius
@mixin border-top-radius($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

// All box sizing
@mixin box-sizing() {
  box-sizing: border-box;
}

// Placeholder
@mixin placeholder($color: $black, $f_size: 1em, $l_height: 1.2em) {
  &::placeholder {
    color: $color;
    font-size: $f_size;
    line-height: $l_height;
  }
}

//Greyscale
@mixin greyscale() {
  filter: grayscale(100%);
}

//Scale
@mixin scale($scale: 1.1) {
  transform: scale($scale);
}

// Transition
@mixin transition(
  $animation_element: all,
  $speed: 0.25s,
  $type: cubic-bezier(0.645, 0.045, 0.355, 1),
  $delay: 0s
) {
  transition: $animation_element $speed $type $delay;
}

@mixin breakpoint($data) {
  .nothing {
    @content();
  }
}
